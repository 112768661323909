/* @noflow */
import React, {useState, useEffect} from 'react';
import {css} from '@emotion/core';
import {logDplWithExtensionParams, EVENTS} from '../util/dpl';

import Layout from '../components/Layout.tsx';
import SEO from '../../../common/components/SEO.tsx';
import {getAppStoreParams} from '../util/extensionMetadata';

const UninstallPage = ({experimentId}) => {
    const [hasFiredPageVisit, setHasFiredPageVisit] = useState(false);

    useEffect(() => {
        const appStoreParams = getAppStoreParams() || {};

        if (!hasFiredPageVisit) {
            logDplWithExtensionParams(EVENTS.MapquestExtensionUninstallPageVisit, appStoreParams);
            setHasFiredPageVisit(true);
        }
    }, [experimentId, hasFiredPageVisit]);

    return (
        <Layout>
            <SEO title="Successfully Uninstalled Mapquest Extension" />
            <div
                css={css`
                    padding: 1rem;
                `}
            >
                <iframe
                    css={css`
                        position: absolute;
                        left: 30%;
                    `}
                    title="uninstall-survey"
                    src="https://docs.google.com/forms/d/e/1FAIpQLSeG_x4SVb0Tpfcc5k5lbWuKEiZeDyAiCS9fOYRjieVKEinG8g/viewform?embedded=true"
                    width="640"
                    height="887"
                    frameBorder="0"
                    marginHeight="0"
                    marginWidth="0"
                >
                    Loading…
                </iframe>
            </div>
        </Layout>
    );
};

export default UninstallPage;
